import * as React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Seo from "../components/Seo"


// markup
const IndexPage = () => {
    return (
        <Layout>
            <Seo title="You have joined our mailing list" />
            <main className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
                <div className="flex-shrink-0 my-auto py-16 sm:py-32">
                    <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">We have added you to our mailing list.</h1>
                    <p className="mt-2 text-base text-gray-500">Thank you we will keep you updated with our progress and future announcements.</p>
                    <div className="mt-6">
                        <Link to="/">
                            <button className="bg-accent hover:bg-accent/80 font-bold py-auto py-auto uppercase tracking-widest text-tiny h-11 px-4 clipped-box" type="submit">Back</button>
                        </Link>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default IndexPage
